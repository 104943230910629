exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-features-tsx": () => import("./../../../src/pages/features.tsx" /* webpackChunkName: "component---src-pages-features-tsx" */),
  "component---src-pages-greener-hosting-tsx": () => import("./../../../src/pages/greener-hosting.tsx" /* webpackChunkName: "component---src-pages-greener-hosting-tsx" */),
  "component---src-pages-heroku-alternative-tsx": () => import("./../../../src/pages/heroku-alternative.tsx" /* webpackChunkName: "component---src-pages-heroku-alternative-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-landing-pages-automate-repetitive-devops-tasks-tsx": () => import("./../../../src/pages/landing-pages/automate-repetitive-devops-tasks.tsx" /* webpackChunkName: "component---src-pages-landing-pages-automate-repetitive-devops-tasks-tsx" */),
  "component---src-pages-landing-pages-clone-preview-environments-from-prod-tsx": () => import("./../../../src/pages/landing-pages/clone-preview-environments-from-prod.tsx" /* webpackChunkName: "component---src-pages-landing-pages-clone-preview-environments-from-prod-tsx" */),
  "component---src-pages-landing-pages-deliver-faster-tsx": () => import("./../../../src/pages/landing-pages/deliver-faster.tsx" /* webpackChunkName: "component---src-pages-landing-pages-deliver-faster-tsx" */),
  "component---src-pages-landing-pages-django-apps-tsx": () => import("./../../../src/pages/landing-pages/django-apps.tsx" /* webpackChunkName: "component---src-pages-landing-pages-django-apps-tsx" */),
  "component---src-pages-landing-pages-flask-apps-tsx": () => import("./../../../src/pages/landing-pages/flask-apps.tsx" /* webpackChunkName: "component---src-pages-landing-pages-flask-apps-tsx" */),
  "component---src-pages-landing-pages-free-your-dev-team-tsx": () => import("./../../../src/pages/landing-pages/free-your-dev-team.tsx" /* webpackChunkName: "component---src-pages-landing-pages-free-your-dev-team-tsx" */),
  "component---src-pages-landing-pages-iterate-merge-sync-tsx": () => import("./../../../src/pages/landing-pages/iterate-merge-sync.tsx" /* webpackChunkName: "component---src-pages-landing-pages-iterate-merge-sync-tsx" */),
  "component---src-pages-landing-pages-laravel-apps-tsx": () => import("./../../../src/pages/landing-pages/laravel-apps.tsx" /* webpackChunkName: "component---src-pages-landing-pages-laravel-apps-tsx" */),
  "component---src-pages-landing-pages-make-data-driven-decisions-tsx": () => import("./../../../src/pages/landing-pages/make-data-driven-decisions.tsx" /* webpackChunkName: "component---src-pages-landing-pages-make-data-driven-decisions-tsx" */),
  "component---src-pages-landing-pages-manage-traffic-surges-tsx": () => import("./../../../src/pages/landing-pages/manage-traffic-surges.tsx" /* webpackChunkName: "component---src-pages-landing-pages-manage-traffic-surges-tsx" */),
  "component---src-pages-landing-pages-nodejs-apps-tsx": () => import("./../../../src/pages/landing-pages/nodejs-apps.tsx" /* webpackChunkName: "component---src-pages-landing-pages-nodejs-apps-tsx" */),
  "component---src-pages-landing-pages-production-environment-cloning-tsx": () => import("./../../../src/pages/landing-pages/production-environment-cloning.tsx" /* webpackChunkName: "component---src-pages-landing-pages-production-environment-cloning-tsx" */),
  "component---src-pages-landing-pages-symfony-apps-tsx": () => import("./../../../src/pages/landing-pages/symfony-apps.tsx" /* webpackChunkName: "component---src-pages-landing-pages-symfony-apps-tsx" */),
  "component---src-pages-pricing-calculator-tsx": () => import("./../../../src/pages/pricing/calculator.tsx" /* webpackChunkName: "component---src-pages-pricing-calculator-tsx" */),
  "component---src-pages-pricing-index-tsx": () => import("./../../../src/pages/pricing/index.tsx" /* webpackChunkName: "component---src-pages-pricing-index-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-pages-startup-promotion-tsx": () => import("./../../../src/pages/startup-promotion.tsx" /* webpackChunkName: "component---src-pages-startup-promotion-tsx" */),
  "component---src-pages-try-it-now-tsx": () => import("./../../../src/pages/try-it-now.tsx" /* webpackChunkName: "component---src-pages-try-it-now-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blogPost.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-case-study-tsx": () => import("./../../../src/templates/caseStudy.tsx" /* webpackChunkName: "component---src-templates-case-study-tsx" */),
  "component---src-templates-event-lp-tsx": () => import("./../../../src/templates/eventLP.tsx" /* webpackChunkName: "component---src-templates-event-lp-tsx" */),
  "component---src-templates-livestream-page-tsx": () => import("./../../../src/templates/livestreamPage.tsx" /* webpackChunkName: "component---src-templates-livestream-page-tsx" */),
  "component---src-templates-paid-ad-page-tsx": () => import("./../../../src/templates/paidAdPage.tsx" /* webpackChunkName: "component---src-templates-paid-ad-page-tsx" */)
}

